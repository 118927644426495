import React from "react"

import Layout from "../components/Layout"

import MasonrySegundaCasa from "../components/MasonrySegundaCasa/MasonrySegundaCasa"

const SegundaCasa = () => {
  return (
    <Layout>
      <div className="container margin-y-lg">
        <section className="text-component">
          <div className="max-width-sm margin-x-auto">
            <h2 className="text-xxl font-heading font-bold">Segunda Casa</h2>
            <p className="text-sm">
              Te invitamos a que mires nuestra segunda casa.
            </p>
          </div>
        </section>
        <div className="margin-y-lg">
          <MasonrySegundaCasa />
        </div>
      </div>
    </Layout>
  )
}

export default SegundaCasa
